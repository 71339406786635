
@font-face {
    font-family: 'Karelia';
    src: url('./fonts/KareliaWeb-Regular.woff2') format('woff2'),
         url('./fonts/KareliaWeb-Regular.woff') format('woff');
    font-weight: 100 300;
    font-style: normal;

}

@font-face {
  font-family: 'Karelia';
  src: url('./fonts/KareliaWeb-Bold.woff2') format('woff2'),
       url('./fonts/KareliaWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'Karelia';
  src: url('./fonts/KareliaWeb-Medium.woff2') format('woff2'),
       url('./fonts/KareliaWeb-Medium.woff') format('woff');
  font-weight: 400 600;
  font-style: normal;

}


@media only screen and (max-width: 450px) {
  html {
    font-size: 14px;
  } 
}

body, button, input {
  margin: 0;
  font-family: 'Karelia';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, button {
  background-color: #0C101C;
  color: white;
}

h1 {
  font-weight: normal;
  line-height: 120%;
  font-size: 2rem;
}

h2 {
  font-weight: normal;
  line-height: 130%;
  font-size: 1.5rem;
}

h3 {
  font-weight: bold;
  line-height: 140%;
  font-size: 1.17rem;
}

p {
  font-weight: 300;
  line-height: 140%;
}